
@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  height: 100%;
}

html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

h2 {
  margin: 10px 0;
}

button, .highcharts-root {
  font-family: 'IBM Plex Sans' !important;
}
