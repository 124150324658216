.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
}

.interested {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-size: 1.4rem;
  width: 250px;
}

.interested > h2 {
  font-size: 2rem;
  margin: 0;
}