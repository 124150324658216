.container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 5px 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  background-color: blue;
  margin-right: 10px;
}
